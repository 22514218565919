import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import siteConfig from '../../data/siteConfig'
import Hero from '../components/hero/hero'
import React from 'react'
import Wrapper from '../components/wrapper'
import SEO from '../components/SEO'
import {Container, Row, Col} from 'react-bootstrap'

const ProjectsPage = (props) => {
  const title = siteConfig.projectsTitle
  const postList = props.data.allMarkdownRemark;

  return (
    <Layout>
      <SEO title="Projects" />
      <Hero
        heroImg={siteConfig.siteCover}
        title={title}/>
      <Wrapper>
          {postList.edges.map(({ node }, i) => (
            <div>
              <br/>
              <h1>{node.frontmatter.projectName}</h1>
              <img src={node.frontmatter.gif} alt="loading"/>
              <br/>
              <a href={node.frontmatter.demoSiteUrl}>Demo Site</a>
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              <a href={node.fields.slug}>Detail Project Info Page</a>
            </div>
          ))}
      </Wrapper>
    </Layout>
  )
}

export default ProjectsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000, sort: { order: DESC, fields: [frontmatter___date] }
      filter: {frontmatter: {projectName: {ne: ""}}}) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    edges {
      node {
        fields{
          slug,
        }
        excerpt(pruneLength: 250)
        frontmatter {
          projectName
          description
          gif
          demoSiteUrl
        }
      }
    }
    }
  }

`
